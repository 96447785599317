import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { ApiService } from 'src/app/shared/services/api.service';
import { UserService } from 'src/app/shared/services/user.service';
import { CommonService } from '../../services/common.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { SubscribeConfirmationPopupComponent } from '../subscribe-confirmation-popup/subscribe-confirmation-popup.component';

@Component({
  selector: 'sb-subscribe-popup',
  templateUrl: './subscribe-popup.component.html',
  styleUrls: ['./subscribe-popup.component.scss']
})
export class SubscribePopupComponent implements OnInit {

  // @Input() close: any;
  @Output() onsubmit: EventEmitter<any> = new EventEmitter();  


  form?: FormGroup;
  loading: boolean = false;
  error?: string;
  loadingSubscription: boolean = false;
  subscriptionData: any;
  subscribing: boolean = false;

  selectedOffer: any;
  isMobile: boolean = false;

  constructor(
    public fb: FormBuilder,
    // public dialogRef: MatDialogRef<JoinBatchComponent>,
    public modal: ModalController,
    public apiService: ApiService,
    public snackbar: MatSnackBar,
    public userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private common: CommonService,
    private plt: Platform
  ) {
    this.form = this.fb.group({
      JoiningCode: new FormControl('', Validators.compose([Validators.required])),
    });
    this.plt.ready().then(() => {
      this.common.canUseNative().then((isNative: boolean) => {
        if (isNative) {
          // Only for debugging!
          this.isMobile = true;
        } else {
        }
      });
    });
    var code = this.route.snapshot.queryParams['code'];
    if(code) {
      this.form.get('JoiningCode').setValue(code);
      this.submitPassword();
    } else {
      this.getSubscriptionDetails();
    }
    // this.route.queryParams.subscribe((val) => {
    //   console.log(val);
    // });
  }

  ngOnInit(): void {
    
  }

  getSubscriptionDetails(code?: any) {
    this.loadingSubscription = true;
    this.apiService.getUserSubscription(code).then((res: any) => {
      this.subscriptionData = res.data;
      var offers = this.subscriptionData.membership.Offers.filter((a) => {
        return a.IsPreferred == true;
      });
      if(offers && offers.length > 0) {
        this.selectedOffer = offers[0];
      } else {
        this.selectedOffer = this.subscriptionData.membership.Offers[0];
      }
      console.log(this.selectedOffer);

      if(this.isMobile && this.subscriptionData.affiliationCode) {
        if(this.subscriptionData.discountedPrice == 0) {
          this.subscribePlan(false);
        } else {
          //TODO: Prompt to go to the web
          // this.snackbar.open("Send email");
          this.subscribePlan(true);
          // this.loadingSubscription = false;
        }
      } else {
        this.loadingSubscription = false;
      }
    }).catch(e => {
      this.snackbar.open(e.msg);
      this.loadingSubscription = false;
    });
  }

  selectOffer(offer){
    this.selectedOffer = offer;
  }

  sanitizeHtml(text: string) {
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }

  startingTrial: boolean = false;
  startTrial() {
    if(this.subscribing) {
      return false;
    }
    this.subscribing = true;
    this.apiService
      .startFreeTrial()
      .then((res: any) => {
        this.startingTrial = false;
        this.router.navigateByUrl('/dashboard/programs?st=1');
      })
      .catch((error) => {
        console.log(error);
        if (!error.msg) {
          error.msg = 'Something went wrong';
        }
        this.snackbar.open(error.msg);
        this.subscribing = false;
      });
  }

  subscribePlan(sendPaymentLink?: any){
    console.log("Subscribing to plan")
    if(this.subscribing) {
      return false;
    }
    if(this.subscriptionData.affiliationCode){
      this.error = undefined;
      if (this.form?.valid) {
        var data = this.form.value;
        this.subscribing = true;
        this.apiService
          .useAffiliationCode(data.JoiningCode, false, false, sendPaymentLink)
          .then((res: any) => {
            if (res.data) {
              if(res.data.OrderReference){
                if(!this.isMobile) {
                  this.onNoClick();
                  this.router.navigateByUrl('/payment/finish/' + res.data.OrderReference + "?t=" + res.data.Token);
                } else {
                  this.showActivationEmailPopup();
                  this.onNoClick();
                }
              } else {
                if(res.data.subscriptionState) {
                  this.userService.setSubscriptionState(res.data.subscriptionState);
                }
                this.openSuccessPopup();
                this.onsubmit.emit(res.data);
                this.onNoClick(res.data);
              }
            }
            this.loadingSubscription = false;
            this.subscribing = false;
          })
          .catch(e => {
            console.log(e);
            if (!e.msg) {
              e.msg = 'Something went wrong. Please try again';
            }
            this.error = e.msg;
            this.snackbar.open(e.msg);
            this.subscribing = false;
          });
      }

    } else {
      this.apiService
        .subscribeMembershipV2(this.selectedOffer.MembershipOfferId, 'core')
        .then((res: any) => {
          console.log(res);
          if (res.data) {
            if(res.data.OrderReference){
              this.onNoClick();
              this.router.navigateByUrl('/payment/finish/' + res.data.OrderReference + "?t=" + res.data.Token);
            } else {
              this.onsubmit.emit(res.data);
              this.onNoClick(res.data);
            }
            this.router.navigateByUrl('/dashboard/programs?st=1');
          }
        })
        .catch((error) => {
          console.log(error);
          if (!error.msg) {
            error.msg = 'Something went wrong';
          }
          this.snackbar.open(error.msg);
          this.subscribing = false;
        });
    }
  }


  openSuccessPopup(){

    const dialogRef = this.dialog.open(SubscribeConfirmationPopupComponent, {
      width: '80%',
      autoFocus: false,
      maxWidth: '800px',
      panelClass: 'round-confirm-box',
      data: {
        title: "Hi, " + this.userService.getUserName(),
        message: this.subscriptionData.membership.WelcomeMessage,
        positive: "Start Week 1",
        showNegative: false,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        var url = '/dashboard/programs/core-level-1';
        if(this.subscriptionData.membership.StartRoute) {
          url = this.subscriptionData.membership.StartRoute;
        }
        this.router.navigateByUrl(url);
      }
    });
  }


  showActivationEmailPopup(){

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '80%',
      maxWidth: '450px',
      autoFocus: false,
      panelClass: 'round-confirm-box',
      data: {
        title: "Hi, " + this.userService.getUserName(),
        message: 'We have sent you an email with the activation steps. Kindly activate the code to proceed further.',
        positive: "Okay",
        showNegative: false,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        
      }
    });
  }


  submitPassword() {
    this.error = undefined
    if (this.form?.valid) {
      var data = this.form.value;
      this.getSubscriptionDetails(data.JoiningCode);
    }

  }

  onNoClick(data?: any) {
    this.modal.dismiss(data);
  }

}
