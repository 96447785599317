<div class="ion-page">
  <ion-content>
    <div class="fullscreen beige-space">
      <div class="box" style="max-width: 800px;">
        <div class="back" (click)="cancelRecording()">
          <mat-icon>close</mat-icon>
        </div>
        <h1 class="title flex">Record and upload your video
          <div class="ml-auto align-self-end pr-4"></div>
        </h1>
        <div class="box-body overflow-auto">
          <ng-container>
            <div class="record-container">
              <div *ngIf="countdown > 0" class="countdown">{{ countdown }}</div>
              <video #recordingEle autoplay *ngIf="isRecording || !videoFile" style="width: 100%; height: auto; margin:0px;"></video>    
              <div class="spinner-border" *ngIf="!isRecording && !videoFile && recorded"></div>
              <video id="playback" [src]="videoFile" controls *ngIf="videoFile && !isRecording" style="width: 100%; height: auto; margin:0px;"></video>
            </div>
          
            <!-- <div *ngIf="isRecording" class="recording-controls">
              <span>Recording... {{ recordingTime }}s</span>
              <ion-button color="danger" (click)="stopRecording()">Stop</ion-button>
            </div> -->
          
            <!-- <div class="action-group" *ngIf="!isRecording">
              <ion-button class="" (click)="startCountdown()" *ngIf="(!videoFile && !uploading)">Record Video</ion-button>
              <ion-button class="" (click)="uploadVideo()" *ngIf="videoFile" [disabled]="!videoFile || uploading">
                <span *ngIf="uploading">Uploading...</span>
                <span *ngIf="!uploading">Upload Video</span>
              </ion-button>
              <ion-button class="" (click)="recaptureVideo()" *ngIf="!uploading && videoFile" [disabled]="uploading">Recapture Video</ion-button>
              <br>
              <ion-button class=" mt-2" (click)="cancelRecording()" *ngIf="uploadedUrl && !uploading" [disabled]="uploading">Cancel New Recording</ion-button>
            </div> -->
          </ng-container>
        </div>
        <div class="d-flex w-100">

          <div *ngIf="isRecording" class="recording-controls">
            <span class="mb-2">Recording... {{ recordingTime }}s</span>
            <button class="btn btn-danger w-100" type="button" (click)="stopRecording()">
              Stop
            </button>
          </div>
          <ng-container *ngIf="!isRecording">
            
            <button class="btn btn-default w-100 " type="button" (click)="startCountdown()" *ngIf="(!videoFile && !uploading)">
              Record Video
            </button>
            <button class="btn btn-default w-100 " type="button" (click)="uploadVideo()" *ngIf="videoFile" [disabled]="!videoFile || uploading">
              <span *ngIf="uploading">Uploading...</span>
              <span *ngIf="!uploading">Upload Video</span>
            </button>
            <button class="btn btn-default w-100 " (click)="recaptureVideo()" *ngIf="!uploading && videoFile" [disabled]="uploading">
              Recapture Video
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </ion-content>
</div>